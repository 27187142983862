<template>
  <div class="modal show">
    <div class="inner-modal">
      <div class="title">Feedback Details ✍️</div>
      <div class="rating-details">
        <div class="details">
          <div class="note-wrapper">
            <div class="add-note-wrap rating-area">
              <span>Rating:</span>
              <div class="image">
                <img :src="emojiURL" class="voted" />
              </div>
            </div>
            <div class="add-note-wrap vote-area">
              <span>User Votes:</span>
              <textarea readonly :value="rating.votes"></textarea>
            </div>
          </div>
          <div class="note-wrapper">
            <div class="add-note-wrap date-area">
              <span>Submitted:</span>
              <textarea readonly :value="`🕖 ${new Date(rating.timestamp).toDateString()}`" :title="new Date(rating.timestamp).toString()" tabindex="0"></textarea>
            </div>
            <div class="add-note-wrap device-area">
              <span>Device Type:</span>
              <textarea readonly :value="`${rating.icon} ${rating.device}`"></textarea>
            </div>
            <div class="add-note-wrap country-area">
              <span>Country:</span>
              <textarea readonly :value="`${rating.flag} ${rating.countryName}`"></textarea>
            </div>
          </div>
          <div class="add-note-wrap">
            <span>Page URL:</span>
            <textarea readonly :value="`📄 ${rating.page}`"></textarea>
          </div>
          <div class="note-wrapper">
            <div class="add-note-wrap response-area">
              <span>Response:</span>
              <textarea readonly :value="rating.response"></textarea>
            </div>
            <div class="add-note-wrap screenshot-area">
              <span>Screenshot:</span>
              <div class="element-wrap">
                <img v-if="rating.screenshot" :src="rating.screenshot" class="screenshot-el" @click="handleImage" />
                <span v-else>No Screenshot Sent</span>
              </div>
            </div>
          </div>
        </div>
        <div class="settings">
          <div class="note-wrapper">
            <div class="add-note-wrap status-area">
              <span>Status:</span>
              <select v-model="status">
                <option value="new">New</option>
                <option value="working">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div class="add-note-wrap note-area">
              <span>Notes:</span>
              <textarea placeholder="Type any comments here" v-model="note"></textarea>
            </div>
          </div>
        </div>
      </div>
      <button v-if="!isLoading" class="swal2-styled swal2-confirm" @click="handleSave">Save</button>
      <button v-if="!isLoading" class="swal2-styled swal2-deny" @click="handleMerge">Merge</button>
      <button v-if="!isLoading" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
      <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
    </div>
  </div>
  <ImageModal v-if="imageDetails" :image="imageDetails" @modal-close="imageDetails = null" />
</template>

<script>
import { ref } from 'vue'
import { Swal, Toast } from '../../composables/getGlobalVariables'
import getUser from '../../composables/getUser'
import ImageModal from './ImageModal.vue'
import useFetch from '../../composables/useFetch'

export default {
  components: { ImageModal },
  props: ['widget', 'ratingId', 'ratings'],
  emits: ['reload', 'modal-close'],
  setup(props, { emit }) {
    const { user } = getUser()
    const { patchData, isLoading, error } = useFetch()
    const { widget, ratingId, ratings } = props
    const rating = ratings.find(el => el.id === ratingId)
    const imageDetails = ref(null)
    const status = ref(rating.status)
    const note = ref(rating.note)
    const emojiURL = Object.values(widget.emojis).find(value => value.value == rating.value).emojiURL
    const emojiIndex = Object.keys(widget.emojis).find(key => widget.emojis[key].value === rating.value);

    const handleImage = (e) => {
      imageDetails.value = e.target.src
    }

    const handleSave = async () => {
      if ((status.value !== rating.status) || (note.value !== rating.note)) {
        await Promise.all([
          patchData(`/feedback/${widget.id}/${rating.id}`, {
            status: status.value,
            note: note.value  
          }),
          patchData(`/widgets/${widget.id}`, {
            [`stats.statusCount.${rating.status}`]: widget.stats.statusCount[rating.status] - 1,
            [`stats.statusCount.${status.value}`]: widget.stats.statusCount[status.value] + 1,
            [`emojis.${emojiIndex}.statusCount.${rating.status}`]: widget.emojis[emojiIndex].statusCount[rating.status] - 1,
            [`emojis.${emojiIndex}.statusCount.${status.value}`]: widget.emojis[emojiIndex].statusCount[status.value] + 1
          })
        ])
        
        if (!error.value) {
          Toast.fire({
            icon: 'success',
            title: 'Awesome!',
            text: 'Response updated successfully',
          })

          emit('reload', rating.id)
          emit('modal-close')
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Uh-oh...',
            text: `Error: ${error.value}`,
          })
        }
      }
    }

    const handleMerge = async () => {
      let availableRatings = ratings.filter(el => el.value === rating.value)
      availableRatings = availableRatings.filter(el => el.id !== rating.id)
      availableRatings.sort((a, b) => (a.votes > b.votes) ? -1 : ((b.votes > a.votes) ? 1 : 0));
      
      const popup = await Swal.fire({
        title: 'Merge Response Into:',
        icon: null,
        showCancelButton: true,
        confirmButtonText: `Merge`,
        html: `<div id="responses-list" style="padding-top:20px;"></div>`,
        didOpen: () => {
          if (availableRatings.length) {
            availableRatings.forEach(el => {
              const ratingEmojiURL = Object.values(widget.emojis).find(value => value.value == el.value).emojiURL
              let option = document.createElement('div')
              option.id = el.id
              option.className = 'feedback-item'
              option.style.marginBottom = '10px'
              option.style.padding = '10px 5px'
              option.onclick = (e) => {
                document.querySelector('#responses-list').querySelectorAll('.feedback-item').forEach(item => { 
                  item.classList.remove('selected-for-merge')
                  item.style.backgroundColor = 'unset'
                  item.style.borderRadius = 'unset'
                  item.querySelector('.votes').querySelector('span').innerHTML = el.votes
                })
                e.target.classList.add('selected-for-merge')
                e.target.style.backgroundColor = '#ffa1a1'
                e.target.style.borderRadius = '5px'
                e.target.querySelector('.votes').querySelector('span').innerHTML = el.votes + rating.votes
              }
              option.innerHTML = `
              <div class="action-votes" style="margin:5px 15px 0 0px;">
                <div class="votes">
                  <span>${el.votes}</span>
                </div>
              </div>
              <article class="action-item">
                <div class="action-rating">
                  <img src="${ratingEmojiURL}">
                </div>
                <div class="action-follow-up">
                  <div class="response ${el.status}" style="top:0;">${el.response}</div>
                </div>
              </article>`;
              document.getElementById('responses-list').appendChild(option)
            })
          } else {
            let list = document.getElementById('responses-list')
            list.style.padding = '10px 0 10px 0'
            list.innerHTML = '<span style="padding-bottom:10px;">No eligible responses yet 🤷</span>' 
          }
        }
      })

      if (popup.isConfirmed) {
        let toMergeInto = document.querySelector('#responses-list').querySelector('.selected-for-merge')
        if (toMergeInto) {
          let widgetId = widget.id
          let curRating = rating.id
          let newRating = toMergeInto.id
          let screenshot = rating.screenshot ? rating.screenshotPath : null

          await patchData(`/widgets/${widgetId}/merge`, { 
            emojiIndex, curRating, newRating, screenshot 
          })

          if (!error.value) {
            emit('reload', newRating, curRating)
            Toast.fire({
              title: 'Success!',
              text: 'Responses merged successfully',
              icon: 'success'
            })
          } else {
            Toast.fire({
              title: 'Oops...',
              text: error.value,
              icon: 'error'
            })
          }

          emit('modal-close')
        }
      }
    }

    const handleClose = () => {
      emit('modal-close')
    }

    return { user, rating, emojiURL, status, note, imageDetails, isLoading, handleImage, 
      handleSave, handleMerge, handleClose }
  }
}
</script>

<style scoped>
  .modal .inner-modal {
    padding: 20px;
    background-color: #ffffff;
  }
  .title {
    position: relative;
    max-width: 100%;
    margin: 0 0 .4em;
    padding: 0;
    color: #595959;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
  }
  button.save {
    background: var(--main);
  }
  button.cancel {
    background: var(--primary);
  }
  .rating-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .rating-details .details {
    width: 70%;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  }
  .rating-details .settings {
    padding: 10px 15px;
  }
  .note-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .rating-details .settings .note-wrapper {
    flex-flow: column;
  }
  .rating-area, .vote-area {
    width: 50%;
  }
  .rating-area {
    margin-right: 10px;
  }
  .device-area {
    width: 20%;
    margin: 0 10px;
  }
  .date-area, .country-area {
    width: 40%;
  }
  .response-area {
    width: 50%;
    margin-right: 5px;
  }
  .screenshot-area {
    width: 50%;
    margin-left: 5px;
  }
  .add-note-wrap {
    padding: 5px 0;
    text-align: left;
  }
  .add-note-wrap * {
    vertical-align: top;
  }
  .add-note-wrap span {
    font-weight: bold;
  }
  .add-note-wrap select, .add-note-wrap textarea {
    width: 100%;
    height: 42px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    background-color: #fff0f0;
    padding: 5px;
    cursor: pointer;
  }
  .add-note-wrap select {
    height: 36px;
    margin: 5px auto 10px auto;
    border: none;
    font-weight: bold;
    padding: 10px;
    background-color: var(--main);
    color: #ffffff;
  }
  .add-note-wrap textarea, .add-note-wrap .image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 36px;
    margin: 5px auto 10px auto;
    padding: 7px;
    line-height: 22px;
    border-radius: 6px;
    border: none;
    resize: none;
    background-color: #fff0f0;
    overflow: auto;
    font-weight: bold;
    cursor: text;
  }
  .date-area textarea {
    cursor: help;
  }
  .response-area textarea, .add-note-wrap .element-wrap {
    height: 100px;
  }
  .note-area textarea {
    height: 150px;
    cursor: text !important;
    background-color: var(--main);
    color: #ffffff;
  }
  .add-note-wrap textarea::placeholder {
    color: #e8e8e8;
    font-weight: normal;
  }
  .add-note-wrap .element-wrap {
    padding: 10px;
    background-color: #fff0f0;
    text-align: center;
    margin: 5px 0;
    border-radius: 6px;
    width: 100%;
  }
  .add-note-wrap .element-wrap img {
    max-height: 80px;
    max-width: 100%;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 2px 40px 2px rgba(0, 0, 0, 0.2);
  }
  .add-note-wrap .element-wrap span {
    color: #ababab;
  }
  .add-note-wrap .image {
    padding: 1px;
  }
  .add-note-wrap .voted {
    width: 33px;
    height: 33px;
  }
</style>