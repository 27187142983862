<template>
  <div class="feedback-list">
    <div class="list">
      <div class="nav-bar">
        <span class="close" @click="handleClose">&times;</span>
        <div>
          <h4>Feedback List 💬</h4>
          <span v-if="pageRef" style="font-size: 12px;">For: 
            <a target="_blank" rel="noreferrer" :href="pageRef.pageHREF">{{ pageRef.pageURL }}</a>
          </span>
        </div>
      </div>
      <div class="select-wrap">
        <select class="filter-select" v-if="widget.settings.votes" v-model="orderBy">
          <option value="votes">🔝 Most Voted</option>
          <option value="createdAt">📅 Newest</option>
        </select>
        <select  v-if="!pageRef" class="filter-select" v-model="filteredStatus">
          <option value="any-status">✨ All</option>
          <option value="new">🏷️ New</option>
          <option value="working">🔨 In Progress</option>
          <option value="completed">✔️ Completed</option>
        </select>
      </div>
      <div v-if="formattedRatings && formattedRatings.length" style="text-align: center;">
        <div v-for="rating in formattedRatings" :key="rating.id" :id="rating.id" class="feedback-item" title="Feedback Details" @click="handleDetails">
          <div class="action-votes" v-if="widget.settings.votes">
            <div class="votes">
              <span>{{ rating.votes }}</span>
            </div>
          </div>
          <article class="action-item">
            <div class="action-rating">
              <img :src="rating.emojiURL">
            </div>
            <div class="action-info">
              <div class="action-follow-up">
                <div :class="rating.status" class="response">
                  <span class="action-type">Response</span><br>
                  {{ rating.response }}
                  <div v-if="rating.screenshot" class="element-screenshot" :style="{ background: `url(${rating.screenshot}) no-repeat center/cover` }"></div>
                </div>
              </div>
              <div class="action-details">
                <span>{{ rating.time }} ago</span>
                <span>{{ rating.device }}</span>
                <span>{{ rating.flag }} {{ rating.countryName }}</span>
              </div>
            </div>
          </article>
        </div>
        <button v-if="!isLoading && formattedRatings.length < totalRatings" @click="fetchFeedback" style="margin-bottom: 15px;">Load More 👇</button>
        <span v-else-if="!isLoading && formattedRatings.length >= totalRatings" style="padding: 10px 0 15px 0;">That's all for now 👍</span>
      </div>
      <div v-if="isLoading" class="info" style="margin: 0; padding-top: 10px;">
        <span>Fetching Responses... ⏳</span>
      </div>
      <div v-if="!error && !isLoading && (!formattedRatings || !formattedRatings.length)" class="info">
        <span>Nothing to show yet 🤷</span>
      </div>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
    <FeedbackModal v-if="ratingId" :widget="widget" :ratingId="ratingId" :ratings="formattedRatings" @reload="fetchUpdatedFeedback" @modal-close="ratingId = null" />
  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from 'vue'
import { formatDistanceToNow } from 'date-fns'
import useFetch from '../../composables/useFetch'
import FeedbackModal from './FeedbackModal.vue'

export default {
  components: { FeedbackModal },
  props: ['loadFeedback', 'widget', 'type', 'page'],
  emits: ['loaded', 'close-list', 'load-more', 'filter-status'],
  setup(props, { emit }) {
    const publicPath = process.env.BASE_URL
    const { loadFeedback, widget, type, page } = toRefs(props)
    const pageRef = ref(page.value)
    const { getData, error, isLoading } = useFetch()
    const orderBy = ref('votes')
    const filteredStatus = ref('any-status')
    const ratingId = ref(null)
    const formattedRatings = ref([])

    const totalRatings = computed(() => {
      if (type.value) {
        if (pageRef.value) {
          return pageRef.value.followUps
        } else {
          if (type.value == 'all-responses') {
            if (filteredStatus.value === 'any-status') {
              return widget.value.stats.followUps
            } else {
              return widget.value.stats.statusCount[filteredStatus.value]
            }
          } else {
            const chosenEmoji = Object.values(widget.value.emojis).find(emoji => emoji.value === type.value);
            if (filteredStatus.value === 'any-status') {
              return chosenEmoji.followUps
            } else {
              return chosenEmoji.statusCount[filteredStatus.value]
            }
          }
        }
      }
    })

    const fetchFeedback = async () => {
      const order = (widget.value.settings.votes && orderBy.value !== 'createdAt') ? 'votes' : 'createdAt'
      const pageQuery = (pageRef.value) ? pageRef.value.pageURL : null
      const startAfter = (formattedRatings.value.length > 0) ? formattedRatings.value[formattedRatings.value.length - 1].id : null
      
      const ratings = await getData(
        `/feedback/${widget.value.id}`, 
        `orderBy=${order}&type=${type.value}&status=${filteredStatus.value}&page=${pageQuery}&limit=20&startAfter=${startAfter}`,
      )

      if (ratings) {
        formattedRatings.value = [...formattedRatings.value, ...ratings.documents.map(el => {
          return { 
            ...el,
            emojiURL: Object.values(widget.value.emojis).find(value => value.value == el.value).emojiURL,
            icon: (el.device === 'Mobile' || el.device === 'Tablet' || el.device === 'Phone') ? '📱' : '🖥️',
            flag: el.countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)),
            date: new Date(el.timestamp).toLocaleString(),
            time: formatDistanceToNow(el.timestamp)
          }
        })]
      }
    }

    const fetchUpdatedFeedback = async (feedbackId, deletedFeedbackId) => {
      if (deletedFeedbackId) {
        formattedRatings.value = formattedRatings.value.filter(el => el.id !== deletedFeedbackId)

        if (pageRef.value) {
          pageRef.value = {
            ...pageRef.value,
            followUps: pageRef.value.followUps - 1
          }
        }
      }
      
      const { feedback } = await getData(`/feedback/${widget.value.id}/${feedbackId}`)
      const index = formattedRatings.value.findIndex(el => el.id === feedbackId)

      if (index > -1) {
        formattedRatings.value[index] = { 
          ...feedback, 
          emojiURL: Object.values(widget.value.emojis).find(value => value.value == feedback.value).emojiURL,
          icon: (feedback.device === 'Mobile' || feedback.device === 'Tablet' || feedback.device === 'Phone') ? '📱' : '🖥️',
          flag: feedback.countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)),
          date: new Date(feedback.timestamp).toLocaleString(),
          time: formatDistanceToNow(feedback.timestamp)
        }
      }
    }

    watch(loadFeedback, async () => {
      if (loadFeedback.value) {
        formattedRatings.value = []
        await fetchFeedback()
        emit('loaded')
      }
    })

    if (widget.value.stats.followUps > 0) {
      if (widget.value.settings.votes) {
        watch(orderBy, (e) => {
          formattedRatings.value = []
          fetchFeedback()
        })
      }

      watch(filteredStatus, (e) => {
        formattedRatings.value = []
        fetchFeedback()
      })
    }

    const handleDetails = (e) => {
      ratingId.value = e.target.id
    }

    const handleClose = () => {
      emit('close-list')
    }

    return { publicPath, widget, orderBy, filteredStatus, type, pageRef, formattedRatings, 
      totalRatings, ratingId, handleClose, handleDetails, fetchFeedback, fetchUpdatedFeedback,
      error, isLoading }
  }
}
</script>

<style>
  .list {
    height: 100%;
    max-width: 100%;
    background: #ffffff;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transition: all .25s ease-in-out;
    transform: translate3d(100%,0,0);
    overflow: scroll;
    padding: 0 20px;
    overflow: auto;
    transform: translate3d(100%,0,0);
  }
  .feedback-list.open .list {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
    transform: translateZ(0);    
  }
  .list .nav-bar {
    width: 100%;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 30px;
    text-align: center;
  }
  .list .nav-bar .close {
    float: left;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    cursor: pointer;
  }
  .list .nav-bar div {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
  }
  .list h4 {
    margin-top: 10px;
  }
  .list .info {
    min-width: 250px;
    text-align: center;
    margin-top: 80px;
  }
  .list .error {
    padding: 50px;
    text-align: center;
  }
  .list hr {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }
  .select-wrap {
    width: 100%; 
    height: 27px; 
    margin: -20px 0 25px 0;
    text-align: right;
  }
  .filter-select {
    height: 27px;
    margin-left: 10px;
    border: 1px solid var(--main);
    border-radius: 7px;
    padding: 0 5px;
    background-color: #fff0f0;
    cursor: pointer;
  }
  .feedback-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 1px solid var(--border);
  }
  .feedback-item:hover {
    background-color: var(--border);
    cursor: pointer;
    border-radius: 5px;
  }
  .feedback-item:hover .action-follow-up .response {
    background-color: #ffcece;
  }
  .feedback-item:hover .action-follow-up .response::after {
    border-right: 10px solid #ffcece;
  }
  .feedback-item * {
    pointer-events: none;
  }
  .action-votes {
    margin: 15px 25px 0 0px;
  }
  .action-votes .votes {
    padding: 5px 10px;
    border: 1px solid var(--border);
    border-radius: 5px;
  }
  .action-item {
    width: 380px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: min-content auto;
  }
  .action-rating {
    cursor: default;
    margin-right: 10px;
    line-height: 1;
  }
  .action-rating img {
    width: 40px;
    height: 40px;
  }
  .action-info {
    display: grid;
    grid-auto-rows: min-content;
    margin-left: 4px;
  }
  .action-follow-up {
    position: relative;
    text-align: left;
  }
  .action-follow-up .action-type {
    color: #A9A9A9;
    font-size: 14px;
    margin: 0 auto;
  }
  .action-follow-up .response {
    position: relative;
    top: -10px;
    color: rgb(22, 22, 22);
    letter-spacing: initial;
    padding: 8px 16px;
    background: #fff0f0;
    border-radius: 3px;
    border-right: 5px solid;
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    white-space: pre-line;
  }
  .action-follow-up .element-screenshot {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 5px;
    right: 5px;
    box-shadow: 0 1px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 5px;
  }
  .action-follow-up .no-response {
    color: #A9A9A9; 
    margin: 0; 
    border-right: none; 
    cursor: default;
  }
  .action-follow-up .no-response .element-screenshot {
    top: 7px;
    right: 10px;
    pointer-events: auto;
  }
  .action-follow-up .completed {
    border-color: rgb(0, 199, 0);
  }
  .action-follow-up .working {
    border-color: yellow;
  }
  .action-follow-up .new {
    border-color: rgba(255, 0, 0, 0.849);
  }
  .action-follow-up .response::after {
    content: "";
    position: absolute;
    left: -8px;
    top: 20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff0f0;
  }
  .action-details {
    text-align: center;
    margin-top: -7px;
  }
  .action-details span {
    font-size: 12px;
    padding: 0 5px 0 0;
  }
  .action-details >:not(:last-child)::after {
    content: "|";
    margin: 0 0 0 5px;
    color: var(--main);
  }
</style>