<template>
  <div class="list">
    <div class="nav-bar">
      <span class="close" @click="emit('close-list')">&times;</span>
      <h4>Page List 📃</h4>
    </div>
    <div v-if="formattedPages && formattedPages.length">
      <div v-for="page in formattedPages" :key="page.id" class="page-item" @click="emit('load-page-ratings', page)">
        <div class="page-details page-url">
          <div class="title">Page</div>
          <div class="stat">{{ page.pageTitle }}<br>
            <div class="page-details-separator"></div>
            <a :href="page.pageHREF" target="_blank" rel="noreferrer" class="page-link">{{ page.pageURL }}</a>
          </div>
        </div>
        <div class="page-details total">
          <div class="title">Total ratings</div>
          <div class="stat">{{ page.totalVotes }}</div>
        </div>
        <div class="page-details">
          <div class="title">Average rating</div>
          <div class="stat">{{ page.avgRating }}</div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="info" style="margin: 0; padding-top: 20px;">
      <span>Fetching Pages... ⏳</span>
    </div>
    <div v-if="!isLoading && (!formattedPages || !formattedPages.length)" class="info">
      <span>Nothing to show yet 🤷</span>
    </div>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue'
import useFetch from '../../composables/useFetch'

export default {
  props: ['loadPages', 'widgetId'],
  emits: ['loaded', 'load-page-ratings', 'close-list'],
  setup(props, { emit }) {
    const { loadPages } = toRefs(props)
    const { getData, error, isLoading } = useFetch()
    const formattedPages = ref([])

    const fetchPages = async () => {
      formattedPages.value = []

      const pages = await getData(
        `/pages/${props.widgetId}`, 
        'orderBy=totalVotes',
      )
      
      if (pages) {
        formattedPages.value = pages.documents.map(el => {
          const url = (el.pageURL !== 'Email') ? `//${el.pageURL}` : 'Email'
          const title = (el.pageURL !== 'Email') ? `📄 ${el.pageTitle}` : '✉️ Email'
          const avgRating = Math.round((el.accVotes / el.totalVotes) * 1e2) / 1e2
          
          return { ...el, pageTitle: title, pageHREF: url, avgRating: avgRating }
        })
      } else {
        formattedPages.value = []
      }
    }

    watch(loadPages, async () => {
      if (loadPages.value) {
        await fetchPages()
        emit('loaded')
      }
    })

    return { emit, formattedPages, error, isLoading }
  }
}
</script>

<style scoped>
  .list {
    height: 100%;
    max-width: 100%;
    background: #ffffff;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transition: all .25s ease-in-out;
    overflow: scroll;
    padding: 0 20px;
    overflow: auto;
    box-shadow: none;
    transform: translate3d(100%,0,0);
  }
  .list.open {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
    transform: translateZ(0);    
  }
  .list .nav-bar {
    margin-bottom: 20px;
  }
  .page-item {
    display: grid;
    width: min-content;
    max-width: 800px;
    margin-bottom: 10px;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content max-content max-content max-content;
    border-bottom: 1px solid var(--border);
    cursor: pointer;
  }
  .page-item:hover {
    border-radius: 5px;
    background-color: var(--border);
  }
  .page-details {
    padding: 15px;
    align-self: flex-start;
  }
  .page-url {
    text-align: left;
    width: 250px;
  }
  .page-details .stat {
    color: rgb(22, 22, 22);
    margin-top: 15px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: initial;
    text-align: center;
  }
  .page-details .page-details-separator {
    margin: 15px 0;
  }
  .page-url .stat {
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    text-align: left;
  }
  .page-url .page-link {
    color: rgb(22, 22, 22);
    opacity: 0.6;
    font-size: 11px;
  }
  .page-details .title {
    color: rgb(125, 125, 125);
    font-size: 12px;
  }
</style>