<template>
  <div v-if="profile && widget" class="page-header">
    <div>
      <h3>Overview</h3>
      <h6><strong>{{ widget.details.name }}</strong></h6>
    </div>
    <div class="menu-wrap">
      <button v-if="(widget.details.userId == profile.id) && (profile.billing.status === 'active') && (profile.billing.plan !== 'free-plan')" @click="handleShare">Share</button>
      <button v-else-if="(widget.details.userId == profile.id) && ((profile.billing.status !== 'active') || (profile.billing.plan === 'free-plan'))" disabled title="Widget sharing is only available on active paid subscriptions" tabindex="0">Share</button>
      <button v-if="(profile.billing.status === 'active') && (profile.billing.plan !== 'free-plan')" class="link" @click="handleReport">Report</button>
      <button v-else class="link" disabled title="Public reports are only available on active paid subscriptions" tabindex="0">Report</button>
      <router-link v-if="profile.billing.status === 'active'" :to="{ name: 'Embed', params: { id: id } }" class="btn tertiary">Embed</router-link>
      <button v-else class="tertiary" disabled title="Widget embedding is only available on active subsctiptions" tabindex="0">Embed</button>
      <router-link :to="{ name: 'Edit', params: { id: id } }" class="btn secondary">Edit</router-link>
      <button class="danger" @click="handleDelete">Delete</button>
    </div>
    <div class="mobile-menu">
      <button id="menu-btn" @click="showMenu = !showMenu">
        <i v-if="!showMenu" class="fas fa-bars"></i>
        <i v-else class="fas fa-times"></i>
      </button>
    </div>
    <div class="dropdown-menu" :class="{ shown: showMenu }">
      <ul>
        <li v-if="(widget.details.userId == profile.id) && (profile.billing.status === 'active') && (profile.billing.plan !== 'free-plan')" @click="handleShare"><i class="fas fa-share"></i><span>Share</span></li>
        <li v-else-if="(widget.details.userId == profile.id) && ((profile.billing.status !== 'active') || (profile.billing.plan === 'free-plan'))" class="disabled"><i class="fas fa-share"></i><span>Share</span></li>
        <li v-if="(profile.billing.status === 'active') && (profile.billing.plan !== 'free-plan')" @click="handleReport"><i class="fas fa-chart-bar"></i><span>Report</span></li>
        <li v-else class="disabled"><i class="fas fa-chart-bar"></i><span>Report</span></li>
        <li v-if="profile.billing.status === 'active'"><router-link :to="{ name: 'Embed', params: { id: id } }"><i class="fas fa-code"></i><span>Embed</span></router-link></li>
        <li v-else class="disabled"><i class="fas fa-code"></i><span>Embed</span></li>
        <li><router-link :to="{ name: 'Edit', params: { id: id } }"><i class="fas fa-edit"></i><span>Edit</span></router-link></li>
        <li @click="handleDelete"><i class="fas fa-trash"></i><span>Delete</span></li>
      </ul>
    </div>
  </div>
  <div v-if="widget" class="container">
    <div class="top-container">
      <div class="inner-container">
        <div class="page-subtitle">Main Stats 🗝️</div>
        <div class="main-stats">
          <div class="stats button" @click="handleFeedback('all-responses', null)"><h3><strong>{{ widget.stats.totalVotes }}</strong></h3><h5>Total Ratings</h5></div>
          <div class="stats average" :title="`Out of ${Object.keys(widget.emojis).length}`" tabindex="0"><h3><strong>{{ averageRating }}</strong></h3><h5>Average Rating</h5></div>
          <div class="stats"><img v-if="mostVoted" :src="mostVoted"><h3 v-else><strong>-</strong></h3><h5>Most Voted</h5></div>
          <div class="stats button" @click="handlePages(event, 'createdAt')"><h3><strong>+</strong></h3><h5>Page Stats</h5></div>
        </div>
      </div>
      <div class="inner-container right">
        <div class="page-subtitle">Ratings Breakdown 🗳️</div>
        <div class="overall-stats">
          <div v-for="emoji in widget.emojis" :key="emoji.value" @click="handleFeedback(emoji.value, null)" class="stats button"><img :src="emoji.emojiURL" class="emoji"><h4>{{ emoji.votes }}</h4></div>
        </div>
      </div>
    </div>
    <div class="inner-container last">
      <div class="page-subtitle">Ratings Over Time 📊 📈</div>
      <Charts :widget="widget" :isReport="false" />
    </div>
  </div>
  <div v-else class="inner-container not-found">
    <h2>Uh-oh... Widget Not Found</h2>
  </div>
  <div class="loadingPage" :class="{ shown: isLoading }"><img src="../assets/images/spinner.svg" alt="Loading..."></div>
  <FeedbackList v-if="widget" :class="{ open: showFeedback }" :loadFeedback="loadFeedback" :widget="widget" :type="feedbackType" :page="feedbackPage" @loaded="loadFeedback = false" @close-list="showFeedback = false" />
  <PageList v-if="widget" :class="{ open: showPages }" :loadPages="loadPages" :widgetId="widget.id" @loaded="loadPages = false" @load-page-ratings="(e) => handleFeedback('all-responses', e)" @close-list="showPages = false" />
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Swal, Toast } from '../composables/getGlobalVariables'
import getUser from '../composables/getUser'
import getCollection from '../composables/getCollection'
import useFetch from '../composables/useFetch'
import FeedbackList from '../components/Overview/FeedbackList.vue'
import PageList from '../components/Overview/PageList.vue'
import Charts from '../components/Overview/Charts.vue'

export default {
  props: ['id'],
  components: { FeedbackList, PageList, Charts },
  setup(props) {
    const router = useRouter()
    const { user } = getUser()
    const { profile, widgets } = getCollection()
    const { patchData, deleteData, error, isLoading } = useFetch()
    const showMenu = ref(false)
    const loadFeedback = ref(false)
    const showFeedback = ref(false)
    const feedbackType = ref(null)
    const feedbackPage = ref(null)
    const showPages = ref(false)
    const loadPages = ref(false)

    const widget = computed(() => {
      if (widgets.value) {
        return widgets.value.find(el => el.id == props.id)
      }
    })

    const averageRating = computed(() => {
      if (widget.value) {
        let totalVotes = widget.value.stats.totalVotes, totalMultiplied = 0

        if (totalVotes > 0) {
          Object.entries(widget.value.emojis).forEach(([key, value]) => {
            totalMultiplied += value.votes * value.value
          });

          return Math.round((totalMultiplied / totalVotes) * 1e2) / 1e2;
        } else {
          return 0
        }
      }
    })

    const mostVoted = computed(() => {
      if (widget.value) {
        const emojiObj = widget.value.emojis;
        let valuesArr = [];

        Object.entries(emojiObj).forEach(([key, value]) => {
          valuesArr.push(value.votes);
        });

        let maxValue = Math.max.apply(Math, valuesArr)

        if (maxValue > 0) {
          const value = Object.values(emojiObj).find(value => value.votes === maxValue);
          return value.emojiURL
        } else {
          return null
        }
      } 
    })

    const handleFeedback = (type, page) => {
      loadPages.value = false
      showPages.value = false
      loadFeedback.value = true
      showFeedback.value = true
      feedbackType.value = type
      feedbackPage.value = page
    }

    const handlePages = () => {
      loadFeedback.value = false
      showFeedback.value = false
      loadPages.value = true
      showPages.value = true
    }

    const handleShare = async () => {
      const popup = await Swal.fire({
        title: 'Share Widget 🔗',
        showCancelButton: true,
        confirmButtonText: 'Share Widget',
        html:
          `<span>Enter the name and (valid) email address of the person you'd like to share the widget with <i class="fas fa-info-circle" title="If they don't have an account, they'll be registered and will receive their account details on email" tabindex="0"></i></span>
          <div id="shared-users" style="max-height:150px;overflow:auto;margin: 20px 0 30px 0;border: 1px solid var(--border);border-bottom: none;border-radius: 5px;"></div>
          <form>
            <input type="text" id="name" required class="swal2-input" placeholder="Full Name" />
            <input type="email" id="email" required class="swal2-input" placeholder="Email Address" style="margin-bottom:20px!important;" />
          </form>`,
        showLoaderOnConfirm: true,
        didOpen: () => {
          document.getElementById('name').focus();

          Object.entries(widget.value.details.sharedUsers).forEach(([key, value]) => {
            const div = document.createElement('div')
            div.style.display = 'flex'
            div.style.justifyContent = 'space-between'
            div.style.padding = '10px 20px'
            div.style.backgroundColor = '#fff0f0'
            div.style.borderBottom = '1px solid var(--border)'
            div.style.borderRadius = '5px'
            div.style.fontSize = '14px'
            div.innerHTML = `<div style="text-align:left">${value.name}<br>${value.email}</div><button class="danger">Delete</button>`
            div.querySelector('button').onclick = () => handleUnshare(key)
            document.querySelector('#shared-users').appendChild(div)
          })
        },
        preConfirm: () => {
          return new Promise(resolve => {
            const name = document.getElementById('name');
            const email = document.getElementById('email');

            if (name.value == '' || email.value == '') {
              Swal.showValidationMessage('Name & Email fields cannot be empty');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
              Swal.showValidationMessage('Email address above doesn\'t look right');
              Swal.getCancelButton().disabled = false;
              Swal.getConfirmButton().disabled = false;
              Swal.hideLoading();
            } else {
              Swal.resetValidationMessage();
              resolve ([name.value, email.value]);
            }
          });
        }
      })

      if (popup.isConfirmed) {
        if (widget.value.details.access.length < 11) {
          await patchData(`/widgets/${props.id}/share`, {
            userName: user.value.displayName,
            name: popup.value[0],
            email: popup.value[1]
          })

          if (!error.value) {
            Toast.fire({
              title: 'Success!',
              text: 'Widget shared successfully',
              icon: 'success' 
            })
          } else {
            Toast.fire({
              title: 'Oops...',
              text: error.value,
              icon: 'error'
            })
          }
        } else {
          Toast.fire({
            title: 'Hey there...',
            text: 'Widgets can only be shared with up to 10 people',
            icon: 'error'
          })
        }
      }
    }

    const handleUnshare = async (userId) => {  
      const newAccess = widget.value.details.access.filter(el => el !== userId)
      const newShared = JSON.parse(JSON.stringify(widget.value.details.sharedUsers))
      delete newShared[userId]
      
      await patchData(`/widgets/${props.id}`, {
        'details.access': newAccess,
        'details.sharedUsers': newShared
      })

      if (!error.value) {
        Toast.fire({
          title: 'Success!',
          text: 'Widget access revoked successfully',
          icon: 'success' 
        })
      } else {
        Toast.fire({
          title: 'Oops...',
          text: error.value,
          icon: 'error'
        })
      }
    }

    const handleReport = async () => {
      if (!widget.value.details.report) {
        const popup = await Swal.fire({
          title: 'No Public Report Found',
          text: 'By creating a public report link, anyone with the link can access your widget results',
          showCancelButton: true,
          confirmButtonText: `Generate Report`
        })

        if (popup.isConfirmed) {
          const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
          const randomId = Array(20).join().split(',').map(() => alpha.charAt(Math.floor(Math.random() * alpha.length))).join('')

          await patchData(`/widgets/${props.id}`, {
            'details.report': randomId
          })

          if (!error.value) {
            const newPopup = await Swal.fire({
              title: 'Public Report',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
              denyButtonText: `Delete Report`,
              html: 
              `<h5>Share your widget results by sending the link to others:</h5>
              <textarea readonly style="resize: none;max-height: 50px;background-color: #fff0f0;border-radius: 5px;border: none;font-weight:600;">https://app.emojise.com/report/${props.id}-${randomId}</textarea>`
            })

            if (newPopup.isDenied) {
              await patchData(`/widgets/${props.id}`, {
                'details.report': null
              })

              if (!error.value) {
                Toast.fire({
                  title: 'Success!',
                  text: 'Report deleted successfully',
                  icon: 'success'
                })
              } else {
                Toast.fire({
                  title: 'Uh-oh...',
                  text: error.value,
                  icon: 'error'
                })
              }
            } 
          } else {
            Toast.fire({
              title: 'Uh-oh...',
              text: error.value,
              icon: 'error'
            })
          }
          
        }
      } else {
        const popup = await Swal.fire({
          title: 'Public Report',
          showCancelButton: true,
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: `Delete Report`,
          html: 
          `<h5>Share your widget results by sending the link to others:</h5>
          <textarea readonly style="resize: none;max-height: 50px;background-color: #fff0f0;border-radius: 5px;border: none;font-weight:600;">https://app.emojise.com/report/${props.id}-${widget.value.details.report}</textarea>`
        })

        if (popup.isDenied) {
          await patchData(`/widgets/${props.id}`, {
            'details.report': null
          })

          if (!error.value) {
            Toast.fire({
              title: 'Success!',
              text: 'Report deleted successfully',
              icon: 'success'
            })
          } else {
            Toast.fire({
              title: 'Uh-oh...',
              text: error.value,
              icon: 'error'
            })
          }
        }
      }
    }

    const handleDelete = async () => {
      const popup = await Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Delete Widget`,
        denyButtonText: `Delete Ratings Only`,
        html: 
        `<h4>Deleting your widget is irreversible and you'll lose all of your widget data.</h4><br>
        <h4><i>This process may take a few minutes.</i></h4><br>
        <h4>Do you confirm?</h4></div>`,
      })

      if (popup.isConfirmed) {
        await deleteData(`/widgets/${props.id}`)

        if (!error.value) {
          Toast.fire({
            title: 'Success!',
            text: 'Widget deleted successfully',
            icon: 'success'
          })

          router.push({ name: 'Dashboard' })
        } else {
          Toast.fire({
            title: 'Oops...',
            text: error.value,
            icon: 'error'
          })
        }   
      } else if (popup.isDenied) {
        await deleteData(`/feedback/${props.id}`)

        if (!error.value) {
          // loadFeedback.value = true
          // loadPages.value = true
          
          Toast.fire({
            title: 'Success!',
            text: 'Widget ratings deleted successfully',
            icon: 'success'
          })
        } else {
          Toast.fire({
            title: 'Oops...',
            text: error.value,
            icon: 'error'
          })
        }
      }
    }
    
    return { showMenu, handleShare, handleReport, handleDelete, profile, widget, 
      averageRating, mostVoted, showFeedback, loadFeedback, feedbackType, feedbackPage, 
      handleFeedback, showPages, loadPages, handlePages, isLoading }
  }
}
</script>

<style scoped>
  .page-header .dropdown-menu {
    top: 105px;
  }
  .page-header .dropdown-menu li.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .container {
    padding: 20px;
  }
  .top-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .top-container .inner-container {
    width: 49%;
    min-height: 185px;
    margin-bottom: 20px;
  }
  .inner-container.last {
    margin-bottom: 0;
    position: relative;
  }
  .main-stats, .overall-stats {
    text-align: center;
  }
  .main-stats h3 {
    margin-bottom: 10px;
  }
  .main-stats img {
    width: 35px;
    height: 35px;
  }
  .overall-stats img {
    margin-bottom: 5px;
  }
  .stats {
    display: inline-block;
    width: 17%;
    padding: 10px 16px;
    text-align: center;
    border-radius: 30px;
    margin: 0 5px;
  }
  .main-stats .stats {
    width: 20%;
    padding: 10px 20px;
  }
  .stats.button {
    border: 1px solid var(--border);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .stats.button:hover {
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .stats.average {
    cursor: help;
  }
</style>