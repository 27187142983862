<template>
  <div class="modal show">
    <div class="inner-modal">
      <div class="navbar">
        <span class="close" @click="handleClose">&times;</span>
      </div>
      <img :src="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['image'],
  emits: ['modal-close'],
  setup({ image }, { emit }) {

    const handleClose = () => {
      emit('modal-close')
    }

    return { handleClose, image }
  }
}
</script>

<style scoped>
  .inner-modal {
    width: 80% !important;
  }
  .inner-modal img {
    box-shadow: 0 2px 40px 2px rgba(0,0,0,0.2);
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
  }
</style>